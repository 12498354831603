const initialState = {
    woSelectedImages: [],
    lineItems:[],
    proposalCopied:{},
    updateAreaUnit:[],
  };
  
  const proposalReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_WO_SELECTED_IMAGES':
        return { ...state, woSelectedImages: action.payload };
        case 'ADD_REMOVE_LINE_ITEMS':
          return { ...state, lineItems: action.payload };
          case 'COPY_ADD_INFO':
            return { ...state, proposalCopied: action.payload };
            case 'UPDATE_AREA_UNIT':
              return {...state,updateAreaUnit:action.payload}
          default:
        return state;
    }
  };
  

  export default proposalReducer;
